<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">OJT Assessment Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <div class="d-flex">
                <label class="col-form-label me-1">Program Name</label>
                <div style="width: 150px">
                  <v-select
                    label="short_name"
                    v-model="program"
                    :options="programs"
                    :clearable="false"
                    :selectable="(options) => isSelectableProgram(options)"
                    @option:selected="
                      getFilterData(program, batch, month, assessor)
                    "
                  >
                  </v-select>
                </div>
              </div>
              <label class="col-form-label me-1 ms-3">Batch</label>
              <div style="width: 150px">
                <v-select
                  label="batch_no"
                  v-model="batch"
                  :options="batches"
                  :clearable="false"
                  :selectable="(options) => options.id != batch.id"
                  @option:selected="
                    getFilterData(program, batch, month, assessor)
                  "
                >
                </v-select>
              </div>
              <label class="col-form-label me-1 ms-3">Month</label>
              <div style="width: 150px">
                <v-select
                  label="name"
                  v-model="month"
                  :options="allMonths"
                  :clearable="false"
                  :selectable="(options) => options.id != month.id"
                  @option:selected="
                    getFilterData(program, batch, month, assessor)
                  "
                >
                </v-select>
              </div>
              <label class="col-form-label me-1 ms-3">Assessor</label>
              <div style="width: 150px">
                <v-select
                  label="name"
                  v-model="assessor"
                  :options="assessors"
                  :clearable="false"
                  :selectable="(options) => options.id != assessor.id"
                  @option:selected="
                    getFilterData(program, batch, month, assessor)
                  "
                >
                </v-select>
              </div>
              <div @click="refreshData" class="icon-css">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive mt-4" v-if="!loading">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="evaluating-weight-list-datatable"
                v-show="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th style="width: 150px">No.</th>
                    <th style="width: 200px">Employee ID</th>
                    <th style="width: 200px">Employee Name</th>
                    <th style="width: 200px">Program Name</th>
                    <th style="width: 200px">Batch</th>
                    <th style="width: 200px">Program Level</th>
                    <th style="width: 200px">Final Result</th>
                    <th class="d-none">Month</th>
                    <th class="d-none">Assessor</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(item, index) in ojtAssessments" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.employee_id }}</td>
                    <td>{{ item.employee_name }}</td>
                    <td>{{ item.program_name }}</td>
                    <td>{{ item.program_batch_no }}</td>
                    <td>{{ item.level }}</td>
                    <td>
                      {{
                        item.hrd_score +
                        "     ( " +
                        (item.hrd_suggestion ?? "") +
                        " )"
                      }}
                    </td>
                    <td class="d-none">{{ getMonth(item.created_at) }}</td>
                    <td class="d-none">{{ item.assessor_employee_name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Orientation Criteria
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { months } from "../../../utlis/months";
import moment from "moment";
import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
  },
  data() {
    return {
      programs: [],
      batches: [],
      allMonths: [],
      assessors: this.$store.getters["odoo/getAllEmployees"],
      assessor: "",
      month: "",
      program: "",
      batch: "",
      ojtAssessments: [],
      title: "OJT Assessment Report Lists",
      headers: [
        "No.",
        "Employee ID",
        "Employee Name",
        "Program Name",
        "Batch",
        "Program Level",
        "Final Result",
      ],
      forExportingData: null,
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },

  methods: {
    getMonth(month) {
      return moment(month).format("MMMM");
    },
    isSelectableProgram(options) {
      if (!this.program || !options) return true;
      return this.program.id != options.id;
    },
    async getPrograms() {
      this.loading = true;
      await axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getBatch() {
      this.loading = true;
      await axios
        .get(`${this.baseUrlHRIS}api/program-batches/list`)
        .then((response) => {
          this.batches = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getAllOjtAssessments() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/ojt-assessment-list-report`)
        .then((response) => {
          this.ojtAssessments = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#evaluating-weight-list-datatable").DataTable();
    },
    getFilterData(program, batch, month, assessor) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        // console.log(settings, dataIndex);
        let programFilter = program?.name;
        let programStage = data[3];
        let batchFilter = batch?.batch_no;
        let batchStage = data[4];
        let monthFilter = month?.name;
        let monthStage = data[7];
        let assessorFilter = assessor?.name;
        let assessorStage = data[8];
        if (
          (programFilter == programStage || !programFilter) &&
          (batchFilter == batchStage || !batchFilter) &&
          (monthFilter == monthStage || !monthFilter) &&
          (assessorFilter == assessorStage || !assessorFilter)
        ) {
          return true;
        }
        return false;
      });
      $("#evaluating-weight-list-datatable").DataTable().draw();
    },
    refreshData() {
      this.program = "";
      this.batch = "";
      this.ojtAssessments = [];
      this.month = "";
      this.assessor = "";
      this.clearFilter();
      this.getAllOjtAssessments();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#evaluating-weight-list-datatable").DataTable().draw();
    },
    exportPdfClick() {
      const dataArray = $("#evaluating-weight-list-datatable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();

      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.pdfLoading = true;
      const sheetData = [];

      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        item[7] = "";
        item[8] = "";
        sheetData.push(item);
      });
      exportPdf(this.headers, sheetData, this.title);

      this.pdfLoading = false;
    },
    exportExcelClick() {
      const dataArray = $("#evaluating-weight-list-datatable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();

      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.excelLoading = true;
      const sheetData = [];

      sheetData.push(this.headers);
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        item[7] = "";
        item[8] = ""; //removing month and assessor from report can't use slice cuz it will warnning by databale
        sheetData.push(item);
      });
      exportExcel(sheetData, "OJT Assessment");
      this.excelLoading = false;
    },
  },
  created() {
    this.allMonths = months;
    this.clearFilter();
    this.getPrograms();
    this.getBatch();
    this.getAllOjtAssessments();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
